@use '../mixin' as *;

.nft-farm-detail-page {
  padding-bottom: 100px;

  .head-content {
    background: var(--primary-color6);
    border: 2px solid var(--primary-color3);
    border-radius: 20px;
    margin-top: 40px;
    display: flex;
    justify-content: space-around;
    padding: 26px 0px;
    @include transition3;
    box-shadow: var(--primary-shadow1);
    &:hover {
      transform: translateY(-10px);
    }
  }
  .tf-tab {
    li.tab-title {
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: var(--primary-color54);
    }
  }
  .content-block {
    &:last-child {
      min-width: 170px;
    }
    .content-title {
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      /* identical to box height, or 187% */

      margin-bottom: 6px;
      color: #999999;
    }
    .content-desc {
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: var(--primary-color54);
    }
  }
  .block-info {
    border: 1px solid #2f2f2f;
    background-color: var(--primary-color6);
    padding: 25px;
    border-radius: 10px;
    box-shadow: var(--primary-shadow1);
    .title-info {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      padding-bottom: 16px;
      border-bottom: 2px solid #27262d;
      margin-bottom: 15px;
      color: var(--primary-color54);
    }
  }
  color: var(--primary-color54);
  .stake-btn {
    background-color: var(--primary-color3);
    color: white;
  }
  .tab-info {
    margin-top: 50px;

    .stake-tab {
      .account-info {
        margin-bottom: 50px;
      }
      .block-content {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        .content-tit {
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          margin-bottom: 4px;
          /* identical to box height, or 187% */

          color: #999999;
        }
      }
      .content-1 {
        margin-bottom: 22px;
      }
      .claim-button {
        width: 100%;
        text-transform: uppercase;
        text-align: center;
        margin-top: 25px;
      }
      .staking-info {
        border: 1px solid #2f2f2f;
        background-color: var(--primary-color6);
      }
    }
    .tab-nft {
      padding-left: 40px;
      .nft-card {
        padding: 25px;
        border: 2px solid #27262d;
        border-radius: 20px;
        width: 270px;
        height: 372px;
        margin-right: 50px;
        margin-bottom: 30px;
        .img-card {
          border-radius: 10px;
        }
        .title-card {
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          color: var(--primary-color54);
          margin: 12px 0px;
        }
      }
    }
  }
  .tab-details {
    .collection-info {
      margin-bottom: 30px;
      display: flex;
      .title-col {
        font-weight: 700;
        font-size: 30px;
        line-height: 39px;
        margin-bottom: 8px;
      }
      .img-col {
        width: 160px;
        border-radius: 10px;
        height: 160px;
        margin-right: 24px;
      }
      .row-desc {
        margin-top: 33px;
      }
    }
    .content-block {
      margin-bottom: 20px;
    }
  }
}
