.ant-message {
  top: 100px;
}
.countdown-text {
  @media (max-width: 580px) {
    .time-text {
      font-size: 16px !important;
    }
    .block-time {
        padding: 0px;
    }
    .submit {
        padding: 7px 20px;
    }
  }
}

.wkit-dialog__header {
    .wkit-dialog__close {
        overflow: hidden;
    }
}
.block-info {
    height: 260px;
    min-height: 260px;
}