.detail-nft-page {
  .tf-item-detail-inner {
    .image {
      .img-nft {
        width: 718px;
        height: 683px;
        object-fit: contain;
      }
    }
    .img-collection {
      width: 46px;
    }
  }
  .attribute-block {
    padding: 11px 17px;
    border-radius: 16px;
    border: 1px solid var(--primary-color21);
    margin-right: 16px;
  }
  .input-price {
    width: 150px;
    height: 44px;
    margin-right: 20px;
  }
}
