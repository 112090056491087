
.login-modal {
  width: 380px !important;
  .ant-modal-close {
    color: var(--primary-color5);
    &:hover {
      background-color: transparent;
      color: var(--primary-color5);
    }
  }
  .ant-modal-content {
    width: 380px;
    border: 2px solid #2FD3DD;
  }
  .wallet-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 13px;
    margin: 3px;
    .img-wallet {
      margin-right: 8px;
      width: 36px;
      height: 36px;
    }
    &:hover {
      padding: 8px 11px;
      border: 2px solid #2FD3DD;
    }
  }
  .ant-modal-body {
    /* Primary */
    color: var(--primary-color5);
    background-color: var(--background-body);
    .title {
      color: var(--primary-color5);
      margin-bottom: 12px;
      text-align: center;
    }
  }
}
