.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
.explore-block {
  .product-media {
    width: 100%;
    height: 290px;
    img {
      object-fit: contain;
      width: auto !important;
      margin: auto;
      height: 100%;
      display: block;
    }
  }
}
