.create-collection-modal {
  width: 1240px !important;
  background: var(--background-body);
  top: 150px !important;
  .ant-modal-close {
    color: var(--primary-color5);
   
  }
  .ant-modal-body {
    background: var(--background-body);
    color: var(--primary-color5);
  }
  .text-pri {
    color: var(--primary-color5);
  }
  .set-item {
    flex-wrap: wrap;
    margin-left: -30px;
    margin-bottom: 20px;
    fieldset {
      width: calc(30% - 10px);
      margin-left: 10px;
    }
  }
  .title-preview {
    color: var(--primary-color5);
    margin-bottom: 24px;
  }
 
  .text-green {
    color: var(--primary-color3);
  }
  .ant-upload-list-item {
    padding: 0px!important;
    border: none!important;
  }
}
.text-red {
  color: red;
}