@use '../../scss/' as *;
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.react-tabs__tab-list {
  @include flex(center, left);
  margin-bottom: 39px;
  @include desktop-1200 {
    flex-wrap: wrap;
    li {
      margin-top: 10px;
    }
  }
  li {
    padding: 7.5px 16px;
    margin-right: 14.5px;
    border-radius: 100px;
    border: 1px solid var(--primary-color36);
    background-color: var(--primary-color22);
    color: #565660;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1em;
    @include transition3;
    cursor: pointer;

    &.react-tabs__tab--selected,
    &:hover {
      background-color: #3749e9;
      border-color: #3749e9;
      color: #fff;
    }
  }
}

.tf-center .react-tabs__tab-list {
  justify-content: center;
  li {
    border-radius: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.dropdown .dropdown-toggle {
  background-color: var(--primary-color6);
  border-color: var(--primary-color36);
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--primary-color5);
}

.show > .btn-primary.dropdown-toggle {
  background-color: var(--primary-color6);
  border-color: var(--primary-color36);
  box-shadow: none;
  outline: none;
  color: var(--primary-color5);
}

.dropdown-menu {
  background-color: var(--primary-color6);
  border-color: var(--primary-color36);
  border-radius: 10px;
  width: 100%;

  .dropdown-item {
    background: transparent;
    padding: 0px 10px;
  }
}
.details-product {
    .avatar {
        img {
            width: 100%;
            height: 40px;
        }
    }
}

.sidebar-explore .dropdown .dropdown-toggle {
  text-align: left;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px;
  padding: 12px 16px;
  border: 1px solid var(--primary-color47);
}
