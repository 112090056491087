.create-launchpad-page {
  color: var(--primary-color5);
  .info-block {
    padding: 60px 77px;
    background: rgb(23, 23, 23);
    width: 100%;
    border: 1px solid var(--product-color18);
    background: var(--primary-color6);
    box-shadow: var(--primary-shadow1);
    border-radius: 16px;
    .title-txt {
    }
  }
}
