.avatar-uploader {
  margin-bottom: 30px;
  .ant-upload-list {
    background: var(--primary-color22);
    .ant-upload-select {
      border: none;
      background: var(--primary-color22);
      /* border */
      &:hover {
      }
      width: 100%;
      height: 320px;
    }
  }

  .ant-upload-list-picture-card-container {
    background: var(--primary-color22);
    width: 100%;
    height: 320px;
  }
  .drag-upload {
    margin-bottom: 0px;
  }
  .text-sec {
    color: var(--primary-color10);
    margin-top: 10px;
  }
  .text-pri {
    color: var(--primary-color5);
    font-size: 16px;
    font-weight: 600;
  }
}
