.admin-page {
  .dropdown-nft {
    #dropdown-basic {
      width: 100%;
    }
  }
  .dashboard-user {
    margin-top: 0px!important;
    height: 100%;
  }
  .img-col {
    width: 50px;
    height: 40px;
    object-fit: contain;
    margin-right: 10px;
  }
}

.select-nft-collection {
  .img-col {
    width: 50px;
    height: 40px;
    object-fit: contain;
    margin-right: 10px;
  }
}