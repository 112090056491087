.coming-soon-page {
  .title {
    letter-spacing: 0.025em;
    text-transform: capitalize;
    line-height: 86px;
    color: #2fd3dd;
    margin: auto;
  }
  .title-block {
    text-align: center;
    padding-top: 8%;
  }
}
