@use '../mixin' as *;

.public-sale-page {
  .head-section {
    margin-bottom: 170px;
    position: relative;

    .content-block {
      position: absolute;
      top: 60px;
      left: 0;
      .heading {
        color: white;
        font-weight: 700;
        font-size: 36px;
        margin-bottom: 0px !important;
      }
      .sub-heading {
        color: white;
        font-weight: 500;
        font-size: 15px;
        line-height: 26px;
        margin-bottom: 30px;
        margin-top: 20px;
      }
    }
    .balance-block {
      @include transition3;
      &:hover {
        transform: translateY(-10px);
        cursor: pointer;
      }
      background: var(--background-body);
      border-radius: 20px;
      box-shadow: var(--primary-shadow1);
      height: 150px;
      margin: auto;
      display: flex;
      .balance-cont {
        display: flex;
        width: 33%;
        padding: 40px;
        &:not(:last-child) {
          border-right: 2px solid var(--primary-color15);
        }
        .block-content {
          font-weight: 700;
          font-size: 28px;
          line-height: 39px;
        }
        .block-txt {
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          /* identical to box height, or 156% */

          color: #a2a2a2;
        }
      }
    }
  }
  .desc-txt {
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    /* identical to box height, or 173% */
    margin-top: 6px;
    text-align: center;
  }
  .content-desc {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--primary-color7);
  }
  .block-time {
    color: var(--primary-color53);
  }
  .submit {
    background-color: var(--primary-color53);
  }
  .title {
    h6 {
      color: var(--primary-color5);
    }
  }
  .icon-content {
    filter: var(--primary-filter53);
  }
  @media (max-width: 576px) {
    .head-section {
      margin-bottom: 60px;
      .balance-block {
        display: block;
        height: auto;
        .balance-cont {
          width: auto;
          padding: 20px;
        }
      }
      .staking-bg {
        height: 821px;
      }
    }
    .icon-content {
      width: 20px;
    }
    .content-desc {
      font-size: 16px;
    }
    .icon-balance {
      object-fit: contain;
      width: 60px;
    }
  }
}
