.profile-page {
  .product-media {
    width: 100%;
    height: 290px;
    img {
      object-fit: contain;
      width: auto !important;
      margin: auto;
      height: 100%;
      display: block;
    }
  }
  .form-edit-profile {
    .user-profile {
      textarea {
        background: var(--product-color17);
        border: 1px solid var(--product-color15);
        border-radius: 8px;
        padding: 7px 19px 7px 19px;
        &::placeholder,
        & {
          font-family: 'DM Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          color: var(--primary-color10);
        }
      }
    }
  }
  .ant-upload-list-item {
    padding: 0px!important;
    border: none!important;
  }
}
