@use '../mixin' as *;

.staking-page {
  .head-section {
    margin-bottom: 170px;
    position: relative;
    .content-block {
      position: absolute;
      top: 60px;
      left: 0;
      .heading {
        color: white;
        font-weight: 700;
        font-size: 36px;
      }
    }
    .balance-block {
      @include transition3;
      &:hover {
        transform: translateY(-10px);
        cursor: pointer;
      }
      background: var(--background-body);
      border-radius: 20px;
      width: 791px;
      box-shadow: var(--primary-shadow1);
      height: 150px;
      margin: auto;
      display: flex;
      .balance-cont {
        display: flex;
        width: 50%;
        padding: 40px;
        &:first-child {
          border-right: 2px solid var(--primary-color15);
        }
        .block-content {
          font-weight: 700;
          font-size: 28px;
          line-height: 39px;
        }
        .block-txt {
          font-weight: 700;
          font-size: 18px;
          line-height: 28px;
          /* identical to box height, or 156% */

          color: #a2a2a2;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .head-section {
      margin-bottom: 60px;
      .balance-block {
        display: block;
        width: auto;
        height: auto;
        .balance-cont {
          width: auto;
          border-right: none !important;
        }
      }
      .staking-bg {
        height: 651px;
      }
    }
    .icon-content {
      width: 20px;
    }
    .content-desc {
      font-size: 16px;
    }
    .icon-balance {
      object-fit: contain;
      width: 60px;
    }
  }
}
