.nft-farm-page {
  padding-bottom: 100px;

  .dashboard-content {
    margin-top: 50px;
    .category-title {
      &::after {
        content: none;
      }
    }
    .table-ranking {
      .col-rankingg {
        width: 16.4% !important;
        &:nth-child(1) {
          .category-title {
            margin-left: 20px;
          }
          width: 22% !important;
        }
        &:nth-child(2) {
          width: 10%!important;
        }
        &:nth-child(3) {
          width: 10% !important;
        }
        &:nth-child(4) {
          width: 17.2%;
        }
        &:nth-child(5) {
          width: 14%;
        }
        &:nth-child(6) {
          width: 15%;
        }
      }
    }
  }
}
