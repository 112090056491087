.tf-add-nft {
  .ant-form-item {
    margin-bottom: 0px;
  }
  .property-block {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .item-property {
      margin-right: 10px;
    }
    .icon-delete {
      cursor: pointer;
      margin-left: 17px;
      i {
        font-size: 24px;
        color: var(--primary-color5);
      }
    }
  }
  .btn-add-more {
    margin-top: 8px;
    padding: 8px 14px;
    cursor: pointer;
    margin-bottom: 15px;
  }
  .title-prop {
    color: var(--primary-color5);
  }
}
